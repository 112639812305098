<template>
  <template v-if="isGroup">
    <GroupCell :params="props.params" />
  </template>
  <div
    v-else-if="
      props.params.node?.rowPinned === 'top' &&
      ['Asset Symbol', 'Name', 'accountName'].includes(props.params.value?.cellName)
    "
    class="elv-table-top-total"
  >
    {{ t('common.total') }}
  </div>
  <div v-else class="elv-table-text">
    <div
      v-if="props.params?.value?.tableType === 'derivative' && props.params.node?.rowPinned !== 'top'"
      class="elv-table-text-derivative"
    >
      <span v-if="props.params.value?.cellName === 'Asset Symbol'" class="is-asset_symbol">
        <template v-if="props.params.data?.asset?.derivative?.name">{{
          props.params.data?.asset?.derivative?.name
        }}</template>
        <template v-else>
          {{ props.params.data?.entityAccount?.name ?? t(`common.${props.params.data.derivativeType.toLowerCase()}s`) }}
        </template>
      </span>
      <span v-else-if="props.params.value?.cellName === 'Margin Asset'">
        {{ props.params.data?.asset?.derivative?.underlyingCurrency?.showSymbol }}
      </span>
      <span v-else-if="props.params.value?.cellName === 'Amount'" class="is-amount">
        {{ formatNumberToSignificantDigits(props.params.data?.balance, 2, '', false) }}
      </span>
      <span v-else-if="props.params.value?.cellName === 'Position Side' && !props.params?.node?.group">
        <template v-if="props.params.value?.positionSide">
          <SvgIcon
            width="14px"
            height="14px"
            :name="props.params.value?.positionSide === 'LONG' ? 'arrow--up-right' : 'arrow--down-right'"
          />
          {{ capitalize(props.params.value?.positionSide) }}
        </template>
        <template v-else>-</template>
      </span>
    </div>
    <img
      v-if="entityAccountPlatform?.name && ['Name', 'accountName'].includes(props.params.value?.cellName)"
      :src="entityAccountPlatform?.logo"
      :alt="entityAccountPlatform?.name"
    />
    <div class="elv-table-text-container">
      <div v-if="props.params?.value" class="elv-table-text__content">
        {{ formatTextValue }}
      </div>
      <div v-else class="elv-table-text__content">-</div>
      <div
        v-if="props.params?.data?.entityAccount?.identity && props.params?.value?.tableType === 'accounts'"
        class="elv-table-text__identity"
      >
        {{ middleEllipsis(props.params?.data?.entityAccount?.identity) }}
        <img
          src="@/assets/img/copy.png"
          alt="copy"
          class="elv-financials-cell-address-main__copy"
          @click="onClickedCopy(props.params?.data?.entityAccount?.identity)"
        />
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { capitalize } from 'lodash-es'
import GroupCell from './GroupCell.vue'
import { ElMessage } from 'element-plus'
import useClipboard from 'vue-clipboard3'
import { middleEllipsis, formatNumberToSignificantDigits } from '@/lib/utils'

const props: any = defineProps<{ params: any }>()
const { t } = useI18n()
const { toClipboard } = useClipboard()

const isGroup = computed(() => {
  return props.params.node?.group && props.params.colDef.columnIndex === 0
})

const entityAccountPlatform = computed(() => {
  return props.params?.data?.entityAccount?.platform
})

const contentWeight = computed(() => {
  return props.params?.value?.tableType === 'accounts' ? 600 : 600
})

const formatTextValue = computed(() => {
  switch (props.params.value?.cellName) {
    case 'Address Name':
      return props.params?.data?.addressName
    case 'Name':
    case 'accountName':
      return props.params?.data?.name ?? props.params?.data?.entityAccount?.name
    case 'Memo':
      if (props.params?.node?.level === 0) return ''
      return props.params?.data?.memo ? props.params?.data?.memo : (props.params?.data?.counterparty?.memo ?? '-')
    default:
      return props.params?.data?.description
  }
})

const onClickedCopy = async (msg: string) => {
  try {
    await toClipboard(msg)
    ElMessage.success(t('message.copiedToClipboard'))
  } catch (e) {
    console.error(e)
  }
}
</script>
<style lang="scss">
.elv-table-top-total {
  color: #0e0f11;
  font-family: 'Plus Jakarta Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.elv-table-text {
  display: flex;
  align-items: center;
  text-align: center;
  width: 100%;

  svg {
    margin-right: 8px;
  }

  img {
    width: 18px;
    height: 18px;
    display: block;
    border-radius: 50%;
    margin-right: 8px;
  }
}

.elv-table-text-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.elv-table-text__identity {
  display: flex;
  align-items: center;
  gap: 4px;
  color: #aaafb6;
  font-family: 'Barlow';
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  img {
    width: 10px;
    height: 10px;
    display: block;
    cursor: pointer;
  }
}

.elv-table-text__content {
  font-family: 'Plus Jakarta Sans';
  font-weight: v-bind('contentWeight');
  font-size: 13px;
  line-height: 13px;
  color: $elv-theme-base-text-color;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.elv-table-text-derivative {
  display: flex;
  align-items: center;
  font-family: 'Plus Jakarta Sans';
  font-size: 13px;
  font-weight: 400;
  line-height: 13px;
  width: 100%;

  svg {
    margin-right: 4px;
  }

  .is-asset_symbol {
    font-weight: 600;
  }

  &:has(.is-amount) {
    justify-content: flex-end;
  }

  span {
    display: flex;
    align-items: center;
  }

  .is-amount {
    font-family: 'Plus Jakarta SansBarlow';
    font-weight: 500;
  }
}
</style>
